@import './src/pages/styles/variables.scss';

.body {

    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: column;

    &.contact {
        justify-content: center;
    }

    .title-desc {
        font-size: 25px;
        color: $secondary_3;
    }

    .contact-us {

        display: flex;
        width: 100%;
        justify-content: space-around;
        flex-direction: column;
    
        .contact-form {
    
            .form-container {
                width: 100%;
    
                .container {
                    width: 50vw;
    
                    .field {
    
                        margin: 15px;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;
                        width: 100%;
    
                        &.contact-submit {
                            background-color: $secondary;
                            border: none;
                            color: white;
                            padding: 16px 50px;
                            text-decoration: none;
                            border-radius:28px;
                            font-size:19px;
                            align-content: center;

                            .sub-text {
                                display: contents;
                                
                                @media screen and (max-width: 1150px) {
                                    display: none;
                                }
                            }
                        }

                        .field-input {
                            // width: 80%;
                        }
    
                        .field-label {
                            margin-right: 10px;
                        }
    
                        .error-msg {
                            color: $error;
                            display: flex;
    
                            .icon {
                                margin-right: 10px;
                            }
                        }
    
                    }
                }
            }
        }
    
        .status-msg {
            display: none;
    
            .icon {
                padding-right: 20px;
                font-size: 25px;
            }
    
            &.show-msg {
                display: flex;
                color: green;
                display: block;
                text-align: center;
            }
        }
    }
}
