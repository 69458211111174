main {

  .header {
    padding: 5px;
    background-color: orange;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 1;
  }

  .body {
    display: flex;
    padding: 5em;
    justify-content: space-between;
    align-items: center;
    min-height: 90vh;
  }
}