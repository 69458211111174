@import './src/pages/styles/variables.scss';


.landing {

    .privacy-details {
    
        .welcome-desc {
    
            width: 70em;
    
            .policy-content {
    
                .card-title {
                    color: $secondary;
                    margin: 10px 0;
                    font-size: 25px;
                }
    
                .updated-date {
                    font-weight: bold;
                    margin: 10px 0;
                    font-size: 16px;
                }
    
                .content {
                    font-size: 14px;
                    text-align: justify;

                    .sub-title {
                        font-size: 16px;
                        font-weight: bold;
                    }

                    .section {
                        padding: 10px 0;
                    }

                    .highlight {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
