html {
    width: 100%;
    
    .app {
        font-family: Georgia, serif;  
        
        .mask {
            position: fixed;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
}