// @import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import './src/pages/styles/variables.scss';

.navigation { 
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: $primary;
    color: $secondary;
    font-size: 1.1em;

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      // height: var(--header-height);
      margin: 1rem;
      width: 100%;
      max-width: unset;

      .logo {
        height: 75px;
        width: 250px;

        .static-visual {
          height: 100%;
          width: 100%;
        }
        // .head, .sub {
        //   color: $secondary;
        // }

        // svg {
        //   // font-family: "Russo One", sans-serif;
        //   width: 150%; height: 50px;
        // }
        // svg text {
        //   animation: stroke 5s infinite alternate;
        //   stroke-width: 2;
        //   stroke: #365FA0;
        //   font-size: 50px;
        // }
      }

      /*.logo {
        max-width: 100px;
        //font-size: 24px;
        margin-left: 10px;
    
          a {
            text-decoration: none;
          }
      }*/

      // @keyframes stroke {
      //   0%   {
      //     fill: rgba(72,138,204,0); stroke: rgba(54,95,160,1);
      //     stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
      //   }
      //   70%  {fill: rgba(72,138,204,0); stroke: rgba(54,95,160,1); }
      //   80%  {fill: rgba(72,138,204,0); stroke: rgba(54,95,160,1); stroke-width: 3; }
      //   100% {
      //     fill: rgba(72,138,204,1); stroke: rgba(54,95,160,0);
      //     stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
      //   }
      // }

      .nav_menu {
        width: 50em;
        @media screen and (max-width: 1150px) {
          display: none;

          &.show-menu {
            display: block;
            width: 100%;
          }
        }

        .nav__list {
          display: flex;
          justify-content: space-between;
          row-gap: 2.5rem;
          
          @media screen and (max-width: 1150px) {
            flex-direction: column;
            margin-top: 20px;
          }

          .nav__sub__item {
            display: none;
            position: absolute;
            z-index: 3;
            margin-top: 40px;
            background-color: $primary;

            @media screen and (max-width: 1150px) {
              position: inherit;
              z-index: unset;
            }

            &.open {
              display: block;
            }

            .nav__item {
              padding: 15px 25px;
              border-width: 1px 1px 0 1px;              
              border-style: solid;

              &:last-of-type {
                border-bottom-width: 1px;
              }              
            }
            
          }

          .nav__item {
            @media screen and (max-width: 1150px) {
              &.sub_menu_parent {
                .menu_icon {              
                  float: right;
                }

              }
            }

            .nav__link {
              // color: var(--title-color);
              // font-weight: var(--font-semi-bold);
              transition: color 0.4s;
              text-decoration: none;
              color: $secondary;
              font-weight: bold;
              width: 100%;
              display: block;
        
              // &:hover {
              //   color: var(--first-color);
              //  }
        
            }
          }
         } 

        .nav__close {
          display: none;
          font-size: 1.5rem;
          // color: var(--title-color);
          cursor: pointer;
          position: absolute;
          top: 1rem;
          right: 1.5rem;

          @media screen and (max-width: 1150px) {
            display: block;
          }
         }
      }

      .nav__toggle {
        display: none;
        font-size: 1.5rem;
        // color: var(--title-color);
        cursor: pointer;

        @media screen and (max-width: 1150px) {
          display: block;

          &.open-menu {
            display: none;
          }
        }
       }
     
     
    //  .nav__cta {
    //   background-color: var(--first-color);
    //   color: var(--title-color);
    //   border: none;
    //   border-radius: 4px;
    //   cursor: pointer;
    //   transition: background-color 0.3s ease;
    //   padding: 0.75rem 1.5rem;
    //  }

     }

    

    // .navigation-menu {
    //   display: flex;
    //   justify-content: space-around;
    //   align-items: center;
    //   width: 50em;

    //   .head_label {
    //     padding: 0 15px;
    //     height: 100%;
    //     cursor: pointer;
    //   }
    // }

    
     
    //  .nav__logo {
    //   color: var(--first-color);
    //   transition: color 0.4s;
    //   font-size: var(--h2-font-size);
    //   font-family: var(--second-font);
    //  }
     
     

     @media screen and (max-width: 1150px) {
      .nav__menu {
        position: fixed;
        top: 0;
        right: -100%;
        background-color: hsla(0, 0%, 6%, 0.2);
        backdrop-filter: blur(16px);
        -webkit-backdrop-filter: blur(16px);
        width: 80%;
        height: 100%;
        padding: 6rem 3rem 0;
        transition: right 0.4s;
      }
    }
  }