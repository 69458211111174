@import './src/pages/styles/variables.scss';

.footer {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    background-color: $primary;
    color: $secondary;
    padding: 20px;

    .sec {

        @media screen and (max-width: 1150px) {
            width: 100%;
            margin-bottom: 30px;
            padding: 0 30px;        
        }

        &.info {
            width: 15%;

            .services-info {
                font-size: 0;
            }

            @media screen and (max-width: 1150px) {
                width: 100%;
            }
        }

        .head_label {
            font-weight: bold;
        }

        .contact_item {
            display: flex;
            flex-direction: row;
            padding: 10px 0;

            .contact_item_link {
                text-decoration: none;
                color: $secondary;
                .icon {
                    padding-right: 10px;
                    display: inline-block;
                }

                &.social_media_link {
                    .icon {
                        font-size: 30px;
                    }
                }
            } 
            
            &.privacy {
                .nav__link {
                    text-decoration: none;
                    color: $secondary;
                    width: 100%;
                    display: block;
                }
            }
        }

        &.services {
            
            .nav__link {
                
                &:first-of-type {
                    padding-top: 10px;
                }

                &:last-of-type {
                    padding-bottom: 10px;
                }

                text-decoration: none;
                color: $secondary;
                width: 100%;
                display: inline-block;
            }
        }
    }
}