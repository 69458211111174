@import './src/pages/styles/variables.scss';

.portfolio-visuals {

    display: flex;
    // flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

    .block {
        // width: 30%;
        max-width: 50%;
        height: 600px;
        margin: 20px 10px;

        @media screen and (max-width: 1150px) {
            // width: 100%;
            max-width: 100%;
            height: 400px;
        }

        // &.wide-block {
        //     width: 50%;

        //     @media screen and (max-width: 1150px) {
        //         width: 100%;
        //     }
        // }

        .portfolio-visual {
            height: 100%;  
            width: 100%;          
        }
    }

    &.comparison {
        .block {
            width: 40%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-direction: column;

            @media screen and (max-width: 1150px) {
                width: 100%;
            }

            .portfolio-visual {
                width: auto;

                @media screen and (max-width: 1150px) {
                    width: 100%;
                }
            }
        }
    }
}

.portfolio-section-title {
    width: 100%;
    border-width: 8px;
    border-style: double;
    border-radius: 10px;
    border-color: $primary_2;
    text-align: center;
    color: $secondary_3;
    font-weight: bold;
    padding: 15px;
    margin: 20px 0;
    font-size: 24px;
}