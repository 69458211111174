.dropdown-menu {
    z-index: 2;
    background: #fff;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    transition: all linear 0.3s;
    display: block;
    border-radius: 0px;
    padding: unset;

    .service-list {
        list-style: none;
        padding: unset;
        margin: unset;

        .service-item {
            padding: 14px;
            transition: all linear 0.3s;            

            &:hover {
                background: gray;
                cursor: pointer;
                color: #fff;
            }

            &.label {
                font-weight: bold;
                cursor: pointer;
                pointer-events: none;
            }
        }
    }    
      
}