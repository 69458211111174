@import './src/pages/styles/variables.scss';

.landing {

    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    .portfolio,
    .project-details {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;        
    
        &.center {
            justify-content: space-around;
        }

        .visuals {
            // height: 50em;
            width: 35%;
            padding-right: 40px;
            padding-bottom: 100px;

            @media screen and (max-width: 1150px) {
                padding-right: 0px;
                width: 35em;
            }

            .carousel-caption {
                background-color: rgba(0, 0 , 0, 0.3);
            }

            .static-visual {
                width: 100%;
                height: 100%;
            }
        }

        .welcome-desc {
            height: 50em;
            width: 55%;
            display: flex;
            flex-direction: column;
            height: 100%;
            align-items: center;            

            @media screen and (max-width: 1150px) {
                width: 100%;
            }

            .big-logo {
                width: 50%;
                // height: 42vh;

                // @media screen and (max-width: 1150px) {
                //     width: 100%;
                // }
        
                .static-visual {
                    max-height: 100%;
                    max-width: 100%;
                }
            }
        
            .title-desc {
                
                font-size: 25px;
                font-style: italic;
                color: $secondary_3;

                &.sub {
                    font-size: 22px;
                }

                .highlight {
                    // font-size: 28px;
                }
            }

            .detailed-desc {

                font-size: 22px;
                padding: 20px 0;
                color: rgba(0, 0, 0, 0.8);
                // text-align: justify;

                &.card {
                    border: 12px double $primary_2;
                    padding: 20px;
                }

                .nav__link {
                    transition: color 0.4s;
                    text-decoration: none;
                    color: $secondary;
                    font-style: italic;
                    width: 100%;
                    display: inline;             
                }
            }
        }
    }

    .separator {
        display: flex;
        justify-content: space-around;
        margin: 4em 0;

        .separator-item {
            height: 8px;
            width: 100%;
            background-color: gold;
        }
    }
}